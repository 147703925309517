<template>
  <div>
    <div class="grid">
      <div class="grid-tr">
        <div class="grid-td">
        </div>
        <div v-for="keytd in 10" class="grid-td" :key="keytd">
          {{keytd}}
        </div>
      </div>
      <div v-for="keytr in 11" class="grid-tr" :key="keytr">
        <div class="grid-td">
          {{keytr-1}}
        </div>
        <div v-for="keytd in 10" class="grid-td" :key="keytd">
          <div class="item">
            <span v-if="inner_data.matrix[keytr-1][keytd]==null">---</span>
            <input v-if="inner_data.matrix[keytr-1][keytd]!=null"
                   @change="colorbg_fn" class="inp"
                   :style="'background: '+colorbg[keytr-1][keytd]"
                   :class="{verr: validerr.get('game_bytype_data.keno_matrix.matrix.'+(keytr-1)+'.'+keytd)=='-'}"
                   v-model="inner_data.matrix[keytr-1][keytd]">
          </div>
        </div>
      </div>
    </div>

    <div class="grid extra">
      <div class="grid-tr">
        <div class="grid-td">
          {{$t('game.game.coef_edit.heads')}}
        </div>
        <div class="grid-td">
          {{$t('game.game.coef_edit.evens')}}
        </div>
        <div class="grid-td">
          {{$t('game.game.coef_edit.tails')}}
        </div>
      </div>
      <div class="grid-tr">
        <div class="grid-td">
          <input class="inp" v-model="inner_data.extra['heads']"
           :class="{verr: validerr.get('game_bytype_data.keno_matrix.extra.heads')=='-'}"
          >
        </div>
        <div class="grid-td">
          <input class="inp" v-model="inner_data.extra['evens']"
           :class="{verr: validerr.get('game_bytype_data.keno_matrix.extra.evens')=='-'}"
          >
        </div>
        <div class="grid-td">
          <input class="inp" v-model="inner_data.extra['tails']"
           :class="{verr: validerr.get('game_bytype_data.keno_matrix.extra.tails')=='-'}"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {inject, defineProps, ref, watchEffect, provide, computed, onMounted} from "vue";

const langEl=inject('langEl')
const dataformatEl=inject('dataformatEl')

const $t=inject('$t')

const props=defineProps({
  data: Object
})

const validerr = inject('validerr')
const verdata = inject('verdata')

const colorbg=ref({
  '0': {'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '', '9': '', '10': ''},
  '1': {'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '', '9': '', '10': ''},
  '2': {'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '', '9': '', '10': ''},
  '3': {'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '', '9': '', '10': ''},
  '4': {'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '', '9': '', '10': ''},
  '5': {'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '', '9': '', '10': ''},
  '6': {'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '', '9': '', '10': ''},
  '7': {'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '', '9': '', '10': ''},
  '8': {'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '', '9': '', '10': ''},
  '9': {'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '', '9': '', '10': ''},
  '10': {'1': '', '2': '', '3': '', '4': '', '5': '', '6': '', '7': '', '8': '', '9': '', '10': ''},
})

const inner_data=ref({})

function validKenoCoef()
{
  let validrule={reg: '^[0-9]+[.]{0,1}[0-9]{0,1}$', mess: '-', min: 0, dépendance: {'game_type': 'Keno'}}

  for(let key in inner_data.value.matrix) {
    let cur=inner_data.value.matrix[key]

    for(let key2 in cur) {
      verdata.value.set('game_bytype_data.keno_matrix.matrix.'+key+'.'+key2, validrule)
    }
  }

  for(let key in inner_data.value.extra) {
    verdata.value.set('game_bytype_data.keno_matrix.extra.'+key, validrule)
  }
}

function colorbg_fn()
{
  for(let key in inner_data.value.matrix) {
    let cur=inner_data.value.matrix[key]

    for(let key2 in cur) {
      let cur2=cur[key2]

      colorbg.value[key][key2]=''

      if(cur2=='0') {
        colorbg.value[key][key2]='#CCCCCC'
      }

      if(cur2>1000) {
        colorbg.value[key][key2]='#FF0000'
      }

      let min=100
      let max=1000
      if(cur2>=min && cur2<=max) {
        let lowcolor=40
        let diff=max-min
        let difflow=diff+lowcolor

        let opti=(cur2-min+lowcolor)/difflow

        colorbg.value[key][key2]='rgba(255, 0, 0, '+opti+')'
      }
    }
  }
}

function prep_data()
{
  inner_data.value=props.data

  colorbg_fn()
}
watchEffect(prep_data)

function mounted_fn()
{
  validKenoCoef()
}
onMounted(mounted_fn)
</script>

<style scoped>
.grid {
  display: table;
  border: #7A7979 1px solid;
  border-collapse: separate;
  border-spacing: 0px;
}

.grid-tr {
  display: table-row;
}

.grid-td {
  display: table-cell;
  width: 60px;
  vertical-align: top;
  text-align: center;
  padding: 5px;
  border: #7A7979 1px solid;
}

.inp {
  width: 50px;
  text-align: center;
}

.extra .grid-td {
  width: 150px;
}

.extra .grid-td input {
  width: 100%;
}

.verr {
  border: red 1px solid;
}

.extra {
  margin-top: 20px;
}
</style>
