<template>
  <div class="container-fluid border border-primary">

    <div class="row form-group">
      <div class="col-2">
        <span>{{ $t('game.shedulereditperiod.interval') }}</span>
      </div>
      <div class="col" :class="{ input_err: interval_err }">
        <div class="row">
          <div class="col-1">
            <input v-model="content.interval_min" v-maska="mask_onlydigital" @change="intervalVerify" @keyup="intervalVerify" type="number" min="1" max="60">
            <br>{{ $t('game.shedulereditperiod.intervalInMinutes') }}
          </div>
          <div class="col-2">
            <Select2 v-model="content.interval_sec" :options="list_interval_sec" @change="intervalVerify" :settings="{ width: '100%' }" />
            {{ $t('game.shedulereditperiod.intervalInSeconds') }}
          </div>
        </div>
        <span v-if="interval_err" class="input_err_text"> {{ interval_err }}</span>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-2">
        <span>{{ $t('game.shedulereditperiod.activeTime') }}</span>
      </div>

      <div class="col-2">
        <span>{{ $t('game.shedulereditperiod.aroundTheClock') }}
          <input v-model="content.around_the_clock" type="checkbox">
        </span>
      </div>

      <div v-if="content.around_the_clock==true" class="col-2 text-right">
        <span>{{ $t('game.shedulereditperiod.time_offset') }}</span>
      </div>
      <div v-if="content.around_the_clock==true" class="col-4">
        <input v-model="content.time_offset" type="number" min="0" max="9">
        <span v-if="validerr.get('schedule.time_offset')" class="validerr">{{validerr.get('schedule.time_offset')}}</span>
      </div>

      <div v-if="content.around_the_clock==false" class="col-2 text-right">
        <span>{{ $t('game.shedulereditperiod.startTime') }}</span>
      </div>
      <div v-if="content.around_the_clock==false" class="col-2">
        <input v-model="content.time_start" type="time" min="0">
      </div>

      <div v-if="content.around_the_clock==false" class="col-2 text-right">
        <span>{{ $t('game.shedulereditperiod.endTime') }}</span>
      </div>
      <div v-if="content.around_the_clock==false" class="col-2">
        <input v-model="content.time_end" type="time" min="0">
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, computed, defineEmits, defineProps, watch, inject, watchEffect, onMounted, onActivated} from "vue";

const $t = inject('$t')
const $i18n = inject('$i18n')

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object
});

const interval_min = ref(1)
const interval_sec = ref(0)

const validerr = inject('validerr')

const content = ref({
  around_the_clock: false,
  interval_min: 0,
  interval_sec: 30,
  time_offset: 0,
  time_start: '00:00',
  time_end: '23:59'
})

const set_input_err = inject('set_input_err')
const interval_err = ref('')

const mask_onlydigital = inject('mask_onlydigital')

const list_interval_sec = ref([
  {id: 0, text: '00'},
  {id: 10, text: '10'},
  {id: 20, text: '20'},
  {id: 30, text: '30'},
  {id: 40, text: '40'},
  {id: 50, text: '50'}
])

function intervalToMinSec()
{
  interval_min.value=Math.ceil(content.value.interval/60)
  interval_sec.value=content.value.interval % 60
}

function MinSecTointerval()
{
  content.value.interval=parseInt(interval_min.value)*60+parseInt(interval_sec.value)
}

function intervalVerify()
{
  if(content.value.interval_min<1) {
    content.value.interval_min=1
  }

  if(content.value.interval_min>60) {
    content.value.interval_min=60
  }

  MinSecTointerval()

  let res = true
  interval_err.value=''

  if(content.value.interval=='') {
    res = false
  }

  if(content.value.interval < 60) {
    res = false
  }

  if(content.value.interval > 3600) {
    res = false
  }

  if(!res) {
    interval_err.value = $t('app.input_err')
  }

  set_input_err(!res)

  return res
}
const interval_err_ver = inject('interval_err_ver')
watch(interval_err_ver, intervalVerify)

function contentGet()
{
  if(props.modelValue!=undefined) {
    content.value=props.modelValue
  }
}
watchEffect(contentGet)

function changeData()
{
  emit('update:modelValue', content.value)
}
watchEffect(changeData)

onActivated(contentGet)
</script>

<style scoped>
</style>