import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'kit/';


class KitService {
  getKitsList() {
    return axios.post(API_URLA + 'getKitsList/');
  }
  getKitsListRace() {
    return axios.post(API_URLA + 'getKitsListRace/');
  }
  getKitsListRace8() {
    return axios.post(API_URLA + 'getKitsListRace8/');
  }
  getKitsListFight() {
    return axios.post(API_URLA + 'getKitsListFight/');
  }
}

export default new KitService();
