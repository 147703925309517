<template>
  <p></p>
  <div class="container-fluid">
    <button v-if="listShow" @click="onSelRow({sel_row: null, is_edit: false, is_new: true})" class="btn-success">{{ $t('game.games.addGame') }}</button>
    <vue-good-table v-if="listShow"
        :columns="columns"
        :rows="rows"
        :row-style-class="rowStyleClassFn"
        :pagination-options="{
          enabled: true,
          perPage: 100,
          perPageDropdown: perPageDropdown,
          nextLabel: $t('app.vue-good-table-next.nextLabel'),
          prevLabel: $t('app.vue-good-table-next.prevLabel'),
          rowsPerPageLabel: $t('app.vue-good-table-next.rowsPerPageLabel'),
          ofLabel: $t('app.vue-good-table-next.ofLabel'),
          pageLabel: $t('app.vue-good-table-next.pageLabel'), // for 'pages' mode
          allLabel: $t('app.vue-good-table-next.allLabel')
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'id', type: 'asc'}
        }"
        compactMode>
      <template #emptystate>
        <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
      </template>
      <template #table-row="props">
          <game-actions v-if="props.column.field == 'actions'" @selRow="onSelRow" :selRow="props.row.id"></game-actions>
      </template>
    </vue-good-table>
    <game v-if="is_edit || is_new" :selRow="sel_row" :params="{is_edit: is_edit, is_new: is_new}"></game>
  </div>
</template>

<script setup>
import GameService from "../../services/game.service";
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import GameActions from "./GameActions.vue";
import Game from "./Game.vue";
import {ref, inject, computed, onMounted, provide, watch} from "vue";
import {useStore} from "vuex";
import BillService from "../../services/bill.service";

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')
const $i18n = inject('$i18n')
const i18nlocale = computed(() => $i18n.locale)

const store = useStore()

const set_result = inject('set_result')

const content = ref("")
const is_edit = ref(false)
const is_new = ref(false)
const sel_row = ref('')

const filtersOptions = ref({'currency': []})

const perPageDropdown = inject('perPageDropdown')
const columns = computed(() => [
      {
        label: $t('game.games.number'),
        field: 'id',
        filterOptions: {enabled: true, placeholder: $t('game.games.number')},
        width: '100px',
        sortFn: sortFn,
      },
      {
        label: $t('game.games.name'),
        field: 'game_name',
        filterOptions: {enabled: true, placeholder: $t('game.games.name')},
        width: '250px',
      },
      {
        label: $t('game.game.gameType'),
        field: 'scheduler_type',
        filterOptions: {enabled: true, placeholder: $t('game.game.gameType')},
      },
      {
        label: $t('game.games.schedule'),
        field: 'schedule',
        filterOptions: {enabled: true, placeholder: $t('game.games.schedule')},
      },
      {
        label: $t('game.games.view'),
        field: 'skin',
        filterOptions: {enabled: true, placeholder: $t('game.games.view')},
        width: '100px',
      },
      {
        label: $t('game.games.currency'),
        field: 'currency',
        filterOptions: {
          enabled: true,
          placeholder: $t('game.games.currency'),
          filterDropdownItems: filtersOptions.value.currency
        },
        width: '100px',
      },
      {
        label: $t('game.games.timezone'),
        field: 'tz',
        filterOptions: {enabled: true, placeholder: $t('game.games.timezone')},
        width: '100px',
      },
      {
        label: $t('game.games.action'),
        field: 'actions',
        html: true,
        width: '170px',
      },
    ])
const rows = ref([])

function newRow()
{
  sel_row.value=null
  is_new.value=true
}

function onSelRow(params)
{
  sel_row.value=params.sel_row
  is_edit.value=params.is_edit
  is_new.value=params.is_new
}
provide('onSelRow', onSelRow)

function getGames()
{
  GameService.getGames().then(
      (response) => {
        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        let currency = []

        let rwn=[]
        for(let key in response.data) {
          let cur=response.data[key]

          if(cur.scheduler_type=='fast') {
            cur.schedule=''
            cur.schedule+=$t('game.shedulereditperiod.interval')+' '
            cur.schedule+=cur.interval
            cur.schedule+=' '+$t('game.shedulereditperiod.period')+' '

            if(cur.around_the_clock==true) {
              cur.schedule+=' '+$t('game.shedulereditperiod.aroundTheClock')
            } else {
              cur.schedule+=' '+$t('game.shedulereditperiod.from')+' '
              cur.schedule+=minutesToTime(cur.time_start)
              cur.schedule+=' '+$t('game.shedulereditperiod.to')+' '
              cur.schedule+=minutesToTime(cur.time_end)
            }
          }

          if(cur['currency']===null) {
            cur['currency']='-'
          }

          currency[cur['currency']]=cur['currency']

          rwn.push(cur)
        }

        filtersOptions.value.currency = Object.keys(currency)
        filtersOptions.value.currency.sort()

        rows.value = rwn;
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}
provide('getGames', getGames)

function listShowFn()
{
  let isShow=true
  if(is_edit.value)
  {
    isShow=false
  }

  if(is_new.value)
  {
    isShow=false
  }

  return isShow
}
const listShow = computed(listShowFn)

function rowStyleClassFn(row)
{
  return 'row_color';
}

function cntSelBill()
{
  let cnt = store.state.bill.selBills.length
  if(cnt==1)
  {
    getGames()
  }
  return cnt
}

function rowSetActiveStatus(id, status)
{
  if(confirm("Do you really want to delete?")) {
    GameService.setActiveStatus(id, status).then(
        () => {
          sel_row.value=null
          is_edit.value=false
          is_new.value=false
          getGames()
        },
        (error) => {
          set_result(error.response.status)
        }
    )
  }
}
provide('rowSetActiveStatus', rowSetActiveStatus)

function minutesToTime(mins) {
  let hours = Math.trunc(mins/60);
  let minutes = mins % 60;

  return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
}
provide('minutesToTime', minutesToTime)

function timeToMinutes(tm)
{
  let res=''
  if(tm!==undefined) {
    let spl = tm.split(':')

    res = parseInt(spl[0])*60+parseInt(spl[1]);
  }

  return res
}
provide('timeToMinutes', timeToMinutes)

function onChangeLang()
{
    getGames()
}

function sortFn(x, y) {
  return (parseInt(x) < parseInt(y) ? -1 : (parseInt(x) > parseInt(y) ? 1 : 0));
}

watch(i18nlocale, onChangeLang)

onMounted(() => {
  getGames()
})
</script>

<style>
.row_color:hover td {
  background: #CCCCCC;
}
</style>
