<template>
  <button @click="editRow(selRow)" type="button" class="btn btn-primary p-1">
    <div class="icon-size"><font-awesome-icon icon="pencil-alt" /></div>
  </button>
  <button @click="rowSetActiveStatus(selRow, 'blocked')" type="button" class="btn btn-secondary p-1" :title="$t('game.game.block')">
    <div class="icon-size"><font-awesome-icon icon="unlock-alt" /></div>
  </button>
</template>

<script setup>
import {ref, defineEmits, defineProps, inject} from "vue";

const emit = defineEmits(['selRow'])
const content = ref("")

const props = defineProps({
  selRow: String
});

const rowSetActiveStatus = inject('rowSetActiveStatus')

function editRow (selRow)
{
  emit('selRow', {is_edit: true, is_new: false, sel_row: selRow})
}

function delBill()
{
  if(confirm("Do you really want to delete?")) {
    rowSetActiveStatus(props.selBill.id, 'deleted')
  }
}
</script>

<style scoped>
  button {
    margin: 3px;
  }
</style>
