<template>
  <div class="bd-example" data-example-id="">
    <div class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.game_type') }}</label>
      <div class="col-md-10">
        <Select2 @select="gameTypeChange($event)" v-model="content.game_type" :disabled="params.is_edit==true" :options="games_types_list" :settings="{ width: '100%' }" />
        <span v-if="validerr.get('game_type')" class="validerr">{{validerr.get('game_type')}}</span>
      </div>
    </div>

    <div class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.version') }}</label>
      <div class="col-md-10">
        <div>
          <Select2 v-model="content.version" :options="version_list" :settings="{ width: '100%' }" />
          <span v-if="validerr.get('version')" class="validerr">{{validerr.get('version')}}</span>
        </div>
      </div>
    </div>

    <div v-show="content.game_type=='Keno'" class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.keno_nabor') }}</label>
      <div class="col-md-10">
        <div v-if="params.is_edit!=true">
          <Select2 v-model="content.individual_data.kit" :options="keno_kit_list" :settings="{ width: '100%' }" />
          <span v-if="validerr.get('game_type')" class="validerr">{{validerr.get('game_type')}}</span>
        </div>
        <div v-if="params.is_edit==true">
          <span v-if="keno_kit_list_obj[content.individual_data.kit]!=undefined">{{ keno_kit_list_obj[content.individual_data.kit].text }}</span>
        </div>
      </div>
    </div>

    <div v-show="content.game_type=='Roulette'" class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.keno_nabor') }}</label>
      <div class="col-md-10">
        <div v-if="params.is_edit!=true">
          <Select2 v-model="content.individual_data.kit" :options="roulette_kit_list" :settings="{ width: '100%' }" />
          <span v-if="validerr.get('game_type')" class="validerr">{{validerr.get('game_type')}}</span>
        </div>
        <div v-if="params.is_edit==true">
          <span v-if="roulette_kit_list_obj[content.individual_data.kit]!=undefined">{{ roulette_kit_list_obj[content.individual_data.kit].text }}</span>
        </div>
      </div>
    </div>

    <div v-show="content.game_type=='Race6'" class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.race_nabor_members') }}</label>
      <div class="col-md-10">
          <Select2 v-model="content.individual_data.kit_type" :disabled="params.is_edit==true" :options="race_kit_list" :settings="{ width: '100%' }" />
          <span v-if="validerr.get('individual_data.kit_type')" class="validerr">{{validerr.get('individual_data.kit_type')}}</span>
      </div>
    </div>

    <div v-show="content.game_type=='Race6'" class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.race_nabor_result') }}</label>
      <div class="col-md-10">
        <Select2 v-model="content.individual_data.kit"  :disabled="params.is_edit==true" :options="race_kit_list_sub" :settings="{ width: '100%' }" />
        <span v-if="validerr.get('individual_data.kit')" class="validerr">{{validerr.get('individual_data.kit')}}</span>
      </div>
    </div>

    <div v-show="content.game_type=='Race8'" class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.race_nabor_members') }}</label>
      <div class="col-md-10">
        <Select2 v-model="content.individual_data.kit_type" :disabled="params.is_edit==true" :options="race8_kit_list" :settings="{ width: '100%' }" />
        <span v-if="validerr.get('individual_data.kit_type')" class="validerr">{{validerr.get('individual_data.kit_type')}}</span>
      </div>
    </div>

    <div v-show="content.game_type=='Race8'" class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.race_nabor_result') }}</label>
      <div class="col-md-10">
        <Select2 v-model="content.individual_data.kit"  :disabled="params.is_edit==true" :options="race8_kit_list_sub" :settings="{ width: '100%' }" />
        <span v-if="validerr.get('individual_data.kit')" class="validerr">{{validerr.get('individual_data.kit')}}</span>
      </div>
    </div>

    <div v-show="content.game_type=='Fight'" class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.race_nabor_members') }}</label>
      <div class="col-md-10">
        <Select2 v-model="content.individual_data.kit_type" :disabled="params.is_edit==true" :options="fight_kit_list" :settings="{ width: '100%' }" />
        <span v-if="validerr.get('individual_data.kit_type')" class="validerr">{{validerr.get('individual_data.kit_type')}}</span>
      </div>
    </div>

    <div v-show="content.game_type=='Fight'" class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.race_nabor_result') }}</label>
      <div class="col-md-10">
        <Select2 v-model="content.individual_data.kit"  :disabled="params.is_edit==true" :options="fight_kit_list_sub" :settings="{ width: '100%' }" />
        <span v-if="validerr.get('individual_data.kit')" class="validerr">{{validerr.get('individual_data.kit')}}</span>
      </div>
    </div>

    <div v-show="content.game_type === 'Race6'" class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.marginality') }}</label>
      <div class="col-md-10">
        <div class="row border border-primary p-0 m-0">
          <div v-for="type in paramsRace" class="col pt-2" :key="type">
            <p>{{ type }}</p> <input v-model="content.individual_data['margin_' + type.toLowerCase()]">
            <p><span v-if="validerr.get('individual_data.margin_' + type.toLowerCase())" class="validerr">{{ validerr.get('individual_data.margin_' + type.toLowerCase()) }}</span></p>
          </div>
        </div>
      </div>
    </div>

    <div v-show="content.game_type=='Race6'" class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.disabling_odds') }}</label>
      <div class="col-md-10">
        <div class="row border border-primary p-0 m-0">
          <div v-for="type in paramsRace" :key="type" class="col p-3">
            <label>{{ type }} <input v-model="content.individual_data['switch_off_' + type.toLowerCase()]" type="checkbox"></label>
          </div>
        </div>
      </div>
    </div>

    <div v-show="content.game_type === 'Race8'" class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.marginality') }}</label>
      <div class="col-md-10">
        <div class="row border border-primary p-0 m-0">
          <div v-for="type in paramsRace" class="col pt-2" :key="type">
            <p>{{ type }}</p> <input v-model="content.individual_data['margin_' + type.toLowerCase()]">
            <p><span v-if="validerr.get('individual_data.margin_' + type.toLowerCase())" class="validerr">{{ validerr.get('individual_data.margin_' + type.toLowerCase()) }}</span></p>
          </div>
        </div>
      </div>
    </div>

    <div v-show="content.game_type=='Race8'" class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.disabling_odds') }}</label>
      <div class="col-md-10">
        <div class="row border border-primary p-0 m-0">
          <div v-for="type in paramsRace" :key="type" class="col p-3">
            <label>{{ type }} <input v-model="content.individual_data['switch_off_' + type.toLowerCase()]" type="checkbox"></label>
          </div>
        </div>
      </div>
    </div>

    <div v-show="content.game_type === 'Fight'" class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.marginality') }}</label>
      <div class="col-md-10">
        <div class="row border border-primary p-0 m-0">
          <div v-for="type in paramsFight" class="col pt-2" :key="type">
            <p>{{ type }}</p> <input v-model="content.individual_data['margin_' + type.toLowerCase()]">
            <p><span v-if="validerr.get('individual_data.margin_' + type.toLowerCase())" class="validerr">{{ validerr.get('individual_data.margin_' + type.toLowerCase()) }}</span></p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="params.is_edit" class="form-group row">
      <label for="example-text-input" class="col-md-2 col-form-label">ID</label>
      <div class="col-md-10">
        <input class="form-control" disabled="disabled" type="text" v-model="content.id" id="example-text-input">
      </div>
    </div>
    <div class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.gameName') }}</label>
      <div class="col-md-10">
        <input class="form-control" type="search" v-model="content.game_name" id="example-search-input">
        <span v-if="validerr.get('game_name')" class="validerr">{{validerr.get('game_name')}}</span>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-2 col-form-label">{{ $t('game.game.currency') }}</label>
      <div class="col-md-10">
        <Select2 v-model="content.currency" :disabled="params.is_edit" :options="myOptions" :settings="{ width: '100%', allowClear: true }" />
        <span v-if="validerr.get('currency')" class="validerr">{{validerr.get('currency')}}</span>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-2 col-form-label">{{ $t('game.game.percent') }}</label>
      <div class="col-md-10" :class="{ input_err: profitability_err }">
        <input class="form-control" type="number" v-maska="mask_onlydigital" v-model="content.profitability" @change="profitability_verify" min="0" max="100">
        <span v-if="validerr.get('profitability')" class="validerr">{{validerr.get('profitability')}}</span>
      </div>
    </div>

    <div v-show="content.game_type==='Keno'" class="form-group row">
      <label @click="spoilers.keno_coef=!spoilers.keno_coef" class="col-md-2 col-form-label link">
        {{$t('game.game.coef_edit.coef_edit_title')}}
      </label>
      <div class="col-md-10">
        <span v-if="validerr.get('game_bytype_data.keno_matrix.alldata')" class="validerr">{{validerr.get('game_bytype_data.keno_matrix.alldata')}}</span>
        <KenoCoef v-show="spoilers.keno_coef"
          v-if="content.game_bytype_data!=undefined && Object.keys(content.game_bytype_data.keno_matrix).length>0"
          :data="content.game_bytype_data.keno_matrix">
        </KenoCoef>
      </div>
    </div>

    <div v-show="false" class="form-group row">
      <label for="example-url-input" class="col-md-2 col-form-label">{{ $t('game.game.view') }}</label>
      <div class="col-md-10">
        <input class="form-control" disabled="disabled" type="url" v-model="content.skin" id="example-url-input">
        <span v-if="validerr.get('skin')" class="validerr">{{validerr.get('skin')}}</span>
      </div>
    </div>

    <div class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.timezone') }}</label>
      <div class="col-md-10">
        <Select2 v-model="content.tz" :disabled="params.is_edit" :options="tz_list" :settings="{ width: '100%' }" />
        <span v-if="validerr.get('tz')" class="validerr">{{validerr.get('tz')}}</span>
      </div>
    </div>

    <div v-if="content.game_type=='Roulette'" class="form-group row">
      <label for="example-search-input" class="col-2 col-form-label">{{ $t('game.game.bet_aliases') }}</label>
      <div class="col-10">
        <Select2 v-model="content.bet_alias_code" :options="bet_aliases_list" :settings="{ width: '100%', allowClear: true }" />
        <span v-if="validerr.get('bet_alias_code')" class="validerr">{{validerr.get('bet_alias_code')}}</span>
      </div>
    </div>

    <div v-if="content.game_type=='Roulette' && (content.kind_of_game || params.is_new)" class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.kindOfGame') }}</label>
      <div class="col-md-10">
        <Select2 v-model="content.kind_of_game" :disabled="params.is_edit" :options="kind_of_game_list" :settings="{ width: '100%' }" />
        <span v-if="validerr.get('kind_of_game')" class="validerr">{{validerr.get('kind_of_game')}}</span>
      </div>
    </div>

    <div class="form-group row">
      <label for="example-search-input" class="col-md-2 col-form-label">{{ $t('game.game.gameType') }}</label>
      <div class="col-md-10">
        <Select2 v-model="content.sched_type" :disabled="params.is_edit" :options="scheduler_type_list" :settings="{ width: '100%' }" />
        <span v-if="validerr.get('sched_type')" class="validerr">{{validerr.get('sched_type')}}</span>
      </div>
    </div>

    <div v-if="content.sched_type=='fast'" class="form-group row">
      <div class="col-2">
        <span>{{ $t('game.game.depthOfPlanning') }}</span>
      </div>
      <div class="col" :class="{ input_err: depth_of_planning_err }" >
        <input class="form-control" v-model="content.depth_of_planning" v-maska="mask_onlydigital" @change="depth_of_planning_verify" type="number" min="0" max="720">
        <span v-if="depth_of_planning_err" class="input_err_text">{{ depth_of_planning_err }}</span>
        <span v-if="validerr.get('depth_of_planning_err')" class="validerr">{{validerr.get('depth_of_planning_err')}}</span>
      </div>
    </div>

    <div v-if="content.sched_type=='fast'" class="row form-group">
      <div class="col-2">
        <span>{{ $t('game.game.startDate') }}</span>
      </div>
      <div class="col-2 fc-startDate">
        <el-config-provider :locale="langEl">
          <el-date-picker
              v-model="content.start_dt"
              type="datetime"
              :format="dataformatEl"
          />
        </el-config-provider>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-2">
        <span>{{ $t('game.game.animation_duration') }}</span>
      </div>
      <div class="col-2 fc-startDate">
        <input class="form-control" type="number" v-maska="mask_onlydigital" v-model="content.animation_duration" @change="profitability_verify" min="0">
        <span v-if="validerr.get('animation_duration')" class="validerr">{{validerr.get('animation_duration')}}</span>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-2 col-form-label">{{ $t('game.game.schedule') }}</label>
      <div class="col-md-10">
        <ShedulerEdit v-if="content.sched_type=='lottery'" v-model="schedulePlan"></ShedulerEdit>
        <ShedulerEditPeriod v-else v-model="schedulePeriod"></ShedulerEditPeriod>
        <span v-if="content.sched_type=='fast'">{{ $t('game.game.cnt_active_events') }}: {{ cnt_event }}</span>
      </div>
    </div>
  </div>

  <div class="form-group row" v-show="['Keno', 'Race6', 'Race8'].indexOf(content.game_type) != -1 && params.is_edit==true">
    <label class="col-md-2 col-form-label">{{$t('game.game.prize_pool_active')}}</label>
    <div class="col-md-10">
      <el-checkbox v-model="content.prize_pool_active" size="large" ></el-checkbox>
    </div>
  </div>

  <div v-if="['Keno', 'Race6', 'Race8'].indexOf(content.game_type) != -1 && params.is_edit==true && content.prize_pool_active" class="bg-light shadow border border-primary rounded-3 p-2 m-2">
    <Controls v-for="item in fields" :key="item" :type="item.type"
              v-model="content[item.code]"
              :label="item.label"
              :list="item.list"
              :code="item.code"
              :adddata="{
                  valid: validerr.get(item.code)
                }"/>
    <p></p>
  </div>

  <div class="row">
    <div class="col-md-2">
      <button @click="rowSave" type="button" class="btn btn-success" :title="$t('game.game.ok')">Ok</button>
      <button @click="closeEdit" type="button" class="btn btn-danger" :title="$t('game.game.cancel')">{{ $t('game.game.cancel1') }}</button>
    </div>
    <div class="col-md-10">
      <button v-if="params.is_edit" @click="rowSetActiveStatus(content.id, 'blocked')" type="button" class="btn btn-secondary" :title="$t('game.game.block')">{{ $t('game.game.block') }}</button>
    </div>
  </div>
</template>

<script setup>
import GameService from "../../services/game.service";
import CurrencieService from "../../services/currencie.service";
import kitService from "../../services/kit.service";
import ShedulerEdit from "./ShedulerEdit.vue";
import ShedulerEditPeriod from "./ShedulerEditPeriod.vue";
import Controls from "@/components/Controls/Main.vue"
import KenoCoef from "./Keno/Coef.vue";
import {ref, defineEmits, defineProps, inject, onMounted, computed, watch, provide, watchEffect} from "vue";

const props = defineProps({
  selRow: String,
  params: Object
});

const langEl=inject('langEl')
const dataformatEl=inject('dataformatEl')

const $t = inject('$t')

const set_result = inject('set_result')

const validatas = inject('validatas')
const validerr = ref(new Map())
provide('validerr', validerr)

const onSelRow = inject('onSelRow')
const getGames = inject('getGames')

const deepClone = inject('deepClone')

const paramsRace = ref(['MAIN', 'EXTRA', 'EXACTA', 'TRIFECTA'])
const paramsFight =ref(['WINNER', 'TIME', 'SECTORS', 'MIX'])

const verdata = ref(new Map([
  ['game_name', {reg: '.+'}],
  ['version', {reg: '.+'}],
  ['currency', {reg: '.+'}],
  ['profitability', {reg: '.+'}],
  ['tz', {reg: '.+'}],
  ['sched_type', {reg: '.+'}],
  ['depth_of_planning', {reg: '.+'}],
  ['schedule.time_offset', {reg: '^[0-9]$', min: 0,  max: 9, dépendance: {'sched_type': 'fast'}}],
  ['prize_pool_load_amount', {reg: '[0-9]*[.]{0,1}[0-9]{0,2}', min: 0.01}],
  ['prize_pool_contrib_prc', {reg: '[0-9]*[.]{0,1}[0-9]{0,2}', min: 1,  max: 100}],
  ['prize_pool_start_prc', {reg: '[0-9]*[.]{0,1}[0-9]{0,2}', min: 0.01,  max: 100}],
  ['individual_data.kit', {reg: '.+', dépendance: {'game_type': ['Race6', 'Race8']}}],
  ['individual_data.kit_type', {reg: '.+', dépendance: {'game_type': ['Race6', 'Race8']}}],
  ['individual_data.margin_main', {reg: '^[0-9]+[.]{0,1}[0-9]{0,2}$', min: 0.01,  max: 0.99, dépendance: {'game_type': ['Race6', 'Race8']}}],
  ['individual_data.margin_extra', {reg: '^[0-9]+[.]{0,1}[0-9]{0,2}$', min: 0.01,  max: 0.99, dépendance: {'game_type': ['Race6', 'Race8']}}],
  ['individual_data.margin_exacta', {reg: '^[0-9]+[.]{0,1}[0-9]{0,2}$', min: 0.01,  max: 0.99, dépendance: {'game_type': ['Race6', 'Race8']}}],
  ['individual_data.margin_trifecta', {reg: '^[0-9]+[.]{0,1}[0-9]{0,2}$', min: 0.01,  max: 0.99, dépendance: {'game_type': ['Race6', 'Race8']}}],
  ['individual_data.margin_winner', {reg: '^[0-9]+[.]{0,1}[0-9]{0,2}$', min: 0.01,  max: 0.99, dépendance: {'game_type': 'Fight'}}],
  ['individual_data.margin_time', {reg: '^[0-9]+[.]{0,1}[0-9]{0,2}$', min: 0.01,  max: 0.99, dépendance: {'game_type': 'Fight'}}],
  ['individual_data.margin_sectors', {reg: '^[0-9]+[.]{0,1}[0-9]{0,2}$', min: 0.01,  max: 0.99, dépendance: {'game_type': 'Fight'}}],
  ['individual_data.margin_mix', {reg: '^[0-9]+[.]{0,1}[0-9]{0,2}$', min: 0.01,  max: 0.99, dépendance: {'game_type': 'Fight'}}],
]));
provide('verdata', verdata)

const rowSetActiveStatus = inject('rowSetActiveStatus')

function fieldsCmp()
{
  let res = []

  res.push({type: 'el-input', code: 'prize_pool_load_amount', label: $t('game.game.prize_pool_load_amount')})
  res.push({type: 'el-input', code: 'prize_pool_contrib_prc', label: $t('game.game.prize_pool_contrib_prc')})
  res.push({type: 'el-input', code: 'prize_pool_start_prc', label: $t('game.game.prize_pool_start_prc')})
  res.push({type: 'el-input', code: 'prize_pool_grow_prc', label: $t('game.game.prize_pool_grow_prc')})
  res.push({type: 'text', code: 'prize_pool_amount', label: $t('game.game.prize_pool_amount')})
  res.push({type: 'text', code: 'prize_pool_win_prc_calc', label: $t('game.game.prize_pool_win_prc_calc')})

  return res
}
const fields = computed(fieldsCmp)

const content = ref({
  game_type: 'Roulette',
  version: 1,
  individual_data: {
    kit: null,
    kit_type: null,
  },
  game_bytype_data: {
    keno_matrix: {}
  },
  skin: 'normal',
  tz: 'UTC+0',
  bet_alias_code: '',
  kind_of_game: 'withtwozeros',
  sched_type: 'lottery',
  depth_of_planning: 20,
  prize_pool_active: false,
  prize_pool_load_amount: 20,
  prize_pool_contrib_prc: 1,
  prize_pool_start_prc: 0.5,
  prize_pool_grow_prc: 1,
  animation_duration: 0
})

const game_bytype_data=ref({})

const spoilers=ref({
  keno_coef: false
})

function setIndividualData () {
  if (content.value.game_type === 'Fight') {
    content.value.individual_data = {
      margin_winner: 0.15,
      margin_time: 0.09,
      margin_sectors: 0.35,
      margin_mix: 0.20,
    }
  } else if (['Race6', 'Race8'].includes(content.value.game_type)) {
    content.value.individual_data = {
      margin_main: 0.15,
      margin_extra: 0.09,
      margin_exacta: 0.35,
      margin_trifecta: 0.20,
      switch_off_main: false,
      switch_off_extra: false,
      switch_off_exacta: false,
      switch_off_trifecta: false,
    }
  }
}


const myValue = ref('')
const myOptions = ref([])

const  bet_aliases_list = ref([
  {id: 'animalitos', text: 'animalitos'},
  {id: 'beisbolito_no_names', text: 'beisbolito_no_names'},
  {id: 'beisbolito_names', text: 'beisbolito_names'},
  {id: 'beisbolito_dominicana', text: 'beisbolito_dominicana'}
])

const mask_onlydigital = inject('mask_onlydigital')

const to_date = inject('to_date')

const input_err = ref(false)

function set_input_err(val)
{
  input_err.value = val
}
provide('set_input_err', set_input_err)

const depth_of_planning_err = ref('')
function depth_of_planning_verify()
{
  if(content.value.sched_type=='lottery') {
    return true
  }

    let res = true
  depth_of_planning_err.value=''

  if(content.value.depth_of_planning=='') {
    res = false
  }

  if(content.value.depth_of_planning<1) {
    res = false
  }

  if(content.value.depth_of_planning>180) {
    res = false
  }

  if(!res) {
    depth_of_planning_err.value = $t('app.input_err')
    input_err.value=true
  }

  return res
}

const profitability_err = ref('')
function profitability_verify()
{
  let res = true
  profitability_err.value=''

  if(content.value.depth_of_planning=='') {
    res = false
  }

  if(content.value.profitability<0) {
    res = false
  }

  //if(content.value.profitability>100) {
  //  res = false
  //}

  if(!res) {
    profitability_err.value = $t('app.input_err')
    input_err.value=true
  }

  return res
}

const interval_err_ver = ref(0)
provide('interval_err_ver', interval_err_ver)

const ds = ref('')

const kind_of_game_list = computed(() => [
  {"id": "withonezeros", "text": $t('game.game.withonezeros')},
  {"id": "withtwozeros", "text": $t('game.game.withtwozeros')}
])

const scheduler_type_list = computed(() => {
  const list = []

  if (content.value.game_type === 'Fight') {
    list.push({"id": "fast", "text": $t('game.game.fast')})
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    content.value.sched_type = 'fast'
  } else {
    list.push({"id": "lottery", "text": $t('game.game.lottery')})
    list.push({"id": "fast", "text": $t('game.game.fast')})
  }

  return list
})

const tz_list = inject('tz_list')

const minutesToTime = inject('minutesToTime')
const timeToMinutes = inject('timeToMinutes')

const schedulePlan = ref([])
const kit = ref('')
const schedulePeriod = ref({
  'around_the_clock': false,
  'interval': 60,
  'interval_min': 1,
  'interval_sec': 0,
  'time_offset': 0,
  'time_start': '00:00',
  'time_end': '23:59'
})

function cnt_event_fn()
{
  let res=0

  if(content.value.sched_type=='fast') {
    schedulePeriod.value.interval=parseInt(schedulePeriod.value.interval_min)*60+parseInt(schedulePeriod.value.interval_sec)

    res=Math.floor(content.value.depth_of_planning/schedulePeriod.value.interval*60)
  }

  return res
}
const cnt_event = computed(cnt_event_fn)

function rowSave()
{

  if(content.value.prize_pool_active==false) {
    if ((null, '').includes(content.value.prize_pool_load_amount)) {
      content.value.prize_pool_load_amount = 20
    }

    if ((null, '').includes(content.value.prize_pool_contrib_prc)) {
      content.value.prize_pool_contrib_prc = 1
    }

    if ((null, '').includes(content.value.prize_pool_start_prc)) {
      content.value.prize_pool_start_prc = 0.5
    }
  }

  interval_err_ver.value++

  if (!depth_of_planning_verify() || input_err.value || !profitability_verify()) {
    return
  }

  let old_schedulePeriod=deepClone(schedulePeriod.value)

  if(content.value.sched_type=='fast') {
    schedulePeriod.value.time_start=timeToMinutes(schedulePeriod.value.time_start)
    schedulePeriod.value.time_end=timeToMinutes(schedulePeriod.value.time_end)

    schedulePeriod.value.interval=parseInt(schedulePeriod.value.interval_min)*60+parseInt(schedulePeriod.value.interval_sec)

    if(content.value.start_dt) {
      let dtfmt=''
      dtfmt=content.value.start_dt.getDate().toString().padStart(2, '0')+'.'+(content.value.start_dt.getMonth()+1).toString().padStart(2, '0')+'.'+content.value.start_dt.getFullYear()+' '
      dtfmt+=content.value.start_dt.getHours().toString().padStart(2, '0')+':'+content.value.start_dt.getMinutes().toString().padStart(2, '0')
      content.value.start_dt=dtfmt
    }

    content.value.schedule=schedulePeriod.value
  }

  if(content.value.sched_type=='lottery') {
    content.value.schedule=schedulePlan.value
    content.value.start_dt=null
    content.value.depth_of_planning=1440
  }

  if(content.value.schedule.interval<60 || content.value.schedule.interval>3600) {
    return
  }

  validerr.value = validatas(content.value, verdata.value)

  let keys=[ ...validerr.value.keys() ];

  let reskeys=keys.filter(function(item) {
    return item.substring(0, 28)=='game_bytype_data.keno_matrix';
  });

  validerr.value.delete('game_bytype_data.keno_matrix.alldata')
  if(reskeys.length>0) {
    validerr.value.set('game_bytype_data.keno_matrix.alldata', $t('app.error'))
  }

  if(validerr.value.size>0) {
    schedulePeriod.value.time_start=old_schedulePeriod.time_start
    schedulePeriod.value.time_end=old_schedulePeriod.time_end

    return
  }

  GameService.saveGame(content.value).then(
      () => {
        getGames()
        onSelRow({is_edit: false, sel_row: '', is_new: false})
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function closeEdit()
{
  onSelRow({is_edit: false, sel_row: '', is_new: false})
}

function getCurency()
{
  CurrencieService.getCurrenciesList().then(
      (response) => {
        myOptions.value = response.data;
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function getGameBytypeData()
{
  GameService.getGameBytypeData().then(
      (response) => {
        game_bytype_data.value = response.data;
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

const race_kit_list = ref([])
const race_kit_list_sub = ref([])

const race8_kit_list = ref([])
const race8_kit_list_sub = ref([])

function getKitsList()
{
  kitService.getKitsList().then(
      (response) => {
        bet_aliases_list.value = response.data;
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function getKitsListRaceSub()
{
  race_kit_list_sub.value=[]

  for(let key in race_kit_list.value) {
    let cur=race_kit_list.value[key]

    if(cur.id==content.value.individual_data.kit_type) {
      race_kit_list_sub.value=JSON.parse(cur.sub)
    }
  }
}
watchEffect(getKitsListRaceSub)

function getKitsListRace()
{
  kitService.getKitsListRace().then(
      (response) => {
        race_kit_list.value = response.data;
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function getKitsListRace8Sub()
{
  race8_kit_list_sub.value=[]

  for(let key in race8_kit_list.value) {
    let cur=race8_kit_list.value[key]

    if(cur.id==content.value.individual_data.kit_type) {
      race8_kit_list_sub.value=JSON.parse(cur.sub)
    }
  }
}
watchEffect(getKitsListRace8Sub)

function getKitsListRace8()
{
  kitService.getKitsListRace8().then(
      (response) => {
        race8_kit_list.value = response.data;
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

const fight_kit_list = ref([])
const fight_kit_list_sub = ref([])

function getKitsListFightSub()
{
  fight_kit_list_sub.value=[]

  for(let key in fight_kit_list.value) {
    let cur=fight_kit_list.value[key]

    if(cur.id==content.value.individual_data.kit_type) {
      fight_kit_list_sub.value=JSON.parse(cur.sub)
    }
  }
}
watchEffect(getKitsListFightSub)

function getKitsListFight()
{
  kitService.getKitsListFight().then(
      (response) => {
        fight_kit_list.value = response.data;
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function getGame()
{
  GameService.getGame(props.selRow).then(
    (response) => {
      content.value = response.data

      if(content.value.game_type=='Keno' && content.value.individual_data.kit==undefined) {
        content.value.individual_data.kit=2
      }

      if(content.value.sched_type=='lottery' && response.data.schedule!=null) {
        let schedule_split=response.data.schedule.split(';')
        content.value.schedule=schedule_split
      }

      myValue.value=content.value.currency;

      if(content.value.sched_type=='fast') {
        if(content.value.schedule.around_the_clock=='1') {
          content.value.schedule.around_the_clock=true
        } else {
          content.value.schedule.around_the_clock=false
        }

        content.value.schedule.time_start=minutesToTime(content.value.schedule.time_start)
        content.value.schedule.time_end=minutesToTime(content.value.schedule.time_end)

        content.value.schedule.interval_min=Math.floor(parseInt(content.value.schedule.interval)/60)
        content.value.schedule.interval_sec=parseInt(content.value.schedule.interval) % 60

        schedulePeriod.value=content.value.schedule

        if(content.value.start_dt) {
          content.value.start_dt=to_date(content.value.start_dt)
        }
      }

      if(content.value.sched_type=='lottery') {
        schedulePlan.value=content.value.schedule
      }
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function gameTypeChange({id, text})
{
  if(['Keno', 'Race6', 'Race8'].includes(id)) {
    content.value.sched_type = 'fast'
  }

  content.value.game_bytype_data.keno_matrix={}
  if(['Keno'].includes(id)) {
    content.value.game_bytype_data.keno_matrix=game_bytype_data.value.keno_matrix
  }

  set_animation_duration()
}

const games_types_list = ref([])
const version_list_all = ref([])
const keno_kit_list = ref([
  {
    id: '0',
    text: '0 (Animation)',
  },
  {
    id: '1',
    text: '1 (2268)',
  },
  {
    id: '2',
    text: '2 (4999)',
  },
])

const keno_kit_list_obj = ref({
  '0': {
    id: '0',
    text: '0 (Animation)'
  },
  '1': {
    id: '1',
    text: '1 (2268)'
  },
  '2': {
    id: '2',
    text: '2 (4999)'
  },
})

const roulette_kit_list = ref([
  {
    id: '0',
    text: '0 (Animation)',
  },
  {
    id: '1',
    text: 'roulette_vg_00 (570)',
  }
])

const roulette_kit_list_obj = ref({
  '0': {
    id: '0',
    text: '0 (Animation)'
  },
  '1': {
    id: '1',
    text: 'roulette_vg_00 (570)'
  }
})

function version_list_fn()
{
  let gtl=[]
  for (let key in version_list_all.value) {
    let cur = version_list_all.value[key]
    if(content.value.game_type===cur.game_type) {
      gtl.push({"id": cur.version, "text": cur.description})
    }
  }

  return gtl
}
const version_list = computed(version_list_fn)

function gameTypes()
{
  GameService.getGameTypes().then(
    (response) => {
      let gtl=[]
      for (let key in response.data) {
        let cur = response.data[key]
        gtl.push({"id": cur.id, "text": $t('game.game.games_list.'+cur.id)})
      }

      games_types_list.value=gtl
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function set_animation_duration()
{
  if(props.params.is_edit!==true) {
    if(content.value.game_type=='Roulette') {
      content.value.animation_duration=21
    }

    if(content.value.game_type=='Keno') {
      content.value.animation_duration=25
    }

    if(['Race6', 'Race8'].includes(content.value.game_type)) {
      content.value.animation_duration=45
    }

    if(content.value.game_type=='Fight') {
      content.value.animation_duration=37
    }
  }
}

function gameVersions()
{
  GameService.getGameVersions().then(
      (response) => {
        version_list_all.value = response.data
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}


if (props.params.is_new) {
  watchEffect(setIndividualData)
}

function mounted_fn()
{
  if(props.params.is_edit) {
    getGame()
  }

  gameTypes()
  gameVersions()
  getCurency()
  getKitsList()
  getKitsListRace()
  getKitsListRace8()
  getKitsListFight()
  set_animation_duration()

  if(props.params.is_new==true) {
    getGameBytypeData()
  }
}
onMounted(mounted_fn)
</script>
