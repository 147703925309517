<template>
  <div class="container-fluid border border-primary">
    <div class="row border border-primary bg-light">
      <div class="col-lg-2 bg-success text-light ">{{ $t('game.sheduleredit.clock') }}</div>
      <div class="col-md-auto">
        <span class="p-1" v-for="pl in hhper" :key="pl"><label><input v-model="checkedHour" type="checkbox" :value="pl"><br><span>{{ pl }}</span></label></span><br>
      </div>
    </div>
    <div class="row border border-primary bg-light">
      <div class="col-lg-2 bg-success text-light">{{ $t('game.sheduleredit.minutes') }}</div>
      <div class="col-md-auto">
        <span class="p-1" v-for="plm in miper" :key="plm"><label><input v-model="checkedMinute" type="checkbox" :value="plm"><br><span>{{ plm }}</span></label></span>
      </div>
    </div>

    <div class="row border border-primary bg-light">
      <div class="col">
        <div>{{ $t('game.sheduleredit.timeGames') }}
          <a v-for="ls in listShed" :key="ls">{{ ls }}; </a>
        </div>
        <button @click="addPlanned" class="btn-success">{{ $t('game.sheduleredit.addGames') }}</button>
      </div>
    </div>
  </div>

  <p>{{ $t('game.sheduleredit.scheduledGames') }} <a v-for="ls in plannedGame" :key="ls"><span class="pr-2">{{ ls }}<span @click="delTime(ls)"><i class="fas fa-trash time_del"></i></span></span></a></p>
</template>

<script setup>
import {ref, computed, defineEmits, defineProps, watch, inject} from "vue";

const $t = inject('$t')

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Array
});

const content = ref('')
const plannedGame = ref([])
const checkedHour = ref([])
const checkedMinute = ref([])
const typePer = [{"id": "mi", "text": $t('game.sheduleredit.clock')}, {"id": "hh", "text": $t('game.sheduleredit.minutes')}]
const hhper = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
const miper = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]

const plannedGameW = computed(() => props.modelValue)

function plannedGameWFn()
{
  plannedGame.value=props.modelValue
}
watch(plannedGameW, plannedGameWFn)

function changePageTitle(data)
{
  emit('update:modelValue', data)
}

function addPlanned()
{
  if(plannedGame.value!=null) {
    plannedGame.value=plannedGame.value.concat(listShed.value)
  } else {
    plannedGame.value=listShed.value
  }

  plannedGame.value = plannedGame.value.filter((item, index, array) => {
    return array.indexOf(item) === index
  })
  plannedGame.value=plannedGame.value.sort()

  changePageTitle(plannedGame.value)
}

function delTime(val)
{
  plannedGame.value = plannedGame.value.filter(function(f) { return f !== val })
  changePageTitle(plannedGame.value)
}

function listShedFn()
{
  let res=[]
  checkedHour.value.forEach((cv) => {
    checkedMinute.value.forEach((cvm) => {
      res.push(cv+':'+cvm)
    })
  })

  return res
}
const listShed = computed(listShedFn)
</script>

<style scoped>
.time_del
{
  cursor: pointer;
  color: red;
}
</style>